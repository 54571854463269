import { ComponentStyleConfig } from '@chakra-ui/react';

export const tabStyles: ComponentStyleConfig = {
  parts: ['tablist', 'tab'],
  baseStyle: {
    tablist: {},
    tab: {
      fontWeight: '500',
    },
  },
  sizes: {},
  variants: {
    slick: {
      tablist: {
        bg: 'white',
        pl: 6,
      },
      tab: {
        px: 0,
        mx: 2,
        borderBottom: '2px solid',
        borderColor: 'transparent',
        _hover: { outline: 'none', boxShadow: 'none' },
        _focus: { outline: 'none', boxShadow: 'none' },
        _selected: {
          borderColor: 'secondary.400',
          color: 'secondary.400',
        },
      },
    },
    solid: {
      tablist: {
        display: 'inline-flex',
        bg: 'grey.100',
        borderRadius: '3xl',
        m: 8,
        p: '3px',
      },
      tab: {
        mb: 0,
        bg: 'grey.100',
        borderRadius: '3xl',
        border: 'none',
        fontWeight: '500',
        py: 1,
        _hover: { outline: 'none', boxShadow: 'none' },
        _focus: { outline: 'none', boxShadow: 'none' },
        _selected: {
          bg: 'secondary.400',
          color: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
        },
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: 'slick',
  },
};
