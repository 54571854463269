// Create color objects
// https://smart-swatch.netlify.app/

import { TinyColor } from '@ctrl/tinycolor';

const brand = {
  50: '#1C503050',
  400: '#1C5030',
  500: '#113921',
};

export const createBrandColors = (mainColor?: string) => {
  const color = mainColor ? mainColor : '#1C5030';

  return {
    brand: {
      50: new TinyColor(color).lighten(50).toString(),
      400: new TinyColor(color).toString(),
      500: new TinyColor(color).darken(10).toString(),
    },
  };
};

export const createSecondaryColors = (mainColor?: string) => {
  const color = mainColor ? mainColor : '#a99670';

  const main = new TinyColor(color);

  return {
    secondary: {
      100: main.lighten(50).toString(),
      200: main.lighten(20).toString(),
      400: main.toString(),
      500: main.darken(10).toString(),
      700: main.darken(30).toString(),
    },
    secondaryText: {
      400: main.isDark() ? '#fff' : '#060c14',
    },
  };
};

const secondary = {
  100: '#e4dcca',
  200: '#d0c5ac',
  400: '#a99670',
  500: '#8f7d56',
  700: '#51452e',
};

const secondaryText = {
  400: '#1f232d',
};

const grey = {
  50: '#eef1fa',
  100: '#d2d5e0',
  200: '#b4b9c8',
  300: '#969db2',
  400: '#79819d',
  500: '#5f6883',
  600: '#4b5167',
  700: '#353a4a',
  800: '#1f232d',
  900: '#060c14',
};

const green = {
  100: '#008A0E10',
  900: '#008A0E',
};

const red = {
  100: '#CB032710',
  900: '#CB0327',
};

export const colors = {
  brand,
  secondary,
  secondaryText,
  grey,
  green,
  red,
};
