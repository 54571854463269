// NB: Keep in sync with the one in the Staff-App

import { BrandColor, SecondaryColor } from '@emotion/react';
import { useRouter } from 'next/router';
import React, {
  createContext,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { useLocationSettingsQuery } from '../../src/generated/graphql';
import { createBrandColors, createSecondaryColors } from '../theme/colors';

type Theme = {
  colors: {
    brand: BrandColor;
    secondary: SecondaryColor;
  };
};

type Settings = {
  languages?: string[] | null;
  currency?: string | null;
  logo?: string | null;
  theme?: Theme | null;
  isPopularCategoryActive?: boolean | null;
};

type LocationSettingsContextType = {
  refetch?: () => void;
  loading?: boolean;
} & Settings;

const CreateLocationSettingsContext = () => {
  return createContext<LocationSettingsContextType>({});
};

type LocationSettingsContextProps = {
  context: React.Context<LocationSettingsContextType>;
} & HTMLAttributes<HTMLDivElement>;

export const LocationSettingsContextProvider: React.FC<
  LocationSettingsContextProps
> = ({ children, context }) => {
  const router = useRouter();
  const locationSlug = router.query.locationSlug as string;

  const locationSettingsQuery = useLocationSettingsQuery({
    variables: {
      locationSlug: locationSlug || '',
    },
  });

  const [settings, setSettings] = useState<Settings>();

  useEffect(() => {
    if (!locationSettingsQuery.data?.locationSettings) return;

    const { languages, currency, theme, logo, isPopularCategoryActive } =
      locationSettingsQuery.data.locationSettings;

    const newTheme = {
      colors: {
        ...createBrandColors(theme?.colors?.brand),
        ...createSecondaryColors(theme?.colors?.secondary),
      },
    };

    setSettings({
      languages,
      currency,
      logo,
      theme: newTheme,
      isPopularCategoryActive,
    });
  }, [locationSettingsQuery.data?.locationSettings]);

  const contextValue: LocationSettingsContextType = {
    languages: settings?.languages,
    currency: settings?.currency,
    logo: settings?.logo,
    theme: settings?.theme,
    isPopularCategoryActive: settings?.isPopularCategoryActive,
    refetch: locationSettingsQuery.refetch,
    loading: locationSettingsQuery.loading,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const locationSettingsContext = CreateLocationSettingsContext();
