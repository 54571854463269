import { ComponentStyleConfig } from '@chakra-ui/react';

export const radioStyles: ComponentStyleConfig = {
  baseStyle: {},
  sizes: {},
  parts: [],
  variants: {
    solid: {
      container: {
        bg: 'grey.50',
        px: 4,
        py: 2,
        _checked: {
          bg: 'secondary.100',
        },
      },
      label: {
        color: 'grey.800',
        _checked: {
          bg: 'secondary.100',
        },
        width: '100%',
      },
      control: {
        bg: 'grey.100',
        border: 'none',
        _checked: {
          _before: {
            content: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.2929 6.70711C17.6834 6.31658 17.6834 5.68342 17.2929 5.29289L16.5858 4.58579C16.1953 4.19526 15.5621 4.19526 15.1716 4.58579L8.10051 11.6569L5.27208 8.82843C4.88155 8.4379 4.24839 8.4379 3.85786 8.82843L3.15076 9.53553C2.76023 9.92606 2.76023 10.5592 3.15076 10.9497L5.97919 13.7782L8.10051 15.8995L10.2218 13.7782L17.2929 6.70711Z' fill='%23000000'/%3E%3C/svg%3E%0A")`,
            display: 'block',
            width: '22px',
            height: '22px',
            pos: 'relative',
            w: '50%',
            h: '50%',
            borderRadius: '50%',
            backgroundColor: 'secondary.100',
            color: 'secondary.100',
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none',
          },
        },
        _focus: {
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
    clean: {},
  },

  defaultProps: {
    variant: 'solid',
  },
};
