import { Heading, HeadingProps, Text, TextProps } from '@chakra-ui/react';

type ComponentProps = {
  children: React.ReactNode;
};

export const Heading1: React.FC<ComponentProps & HeadingProps> = ({
  children,
  ...props
}) => (
  <Heading fontSize='36px' {...props} fontWeight='700'>
    {children}
  </Heading>
);

export const Heading2: React.FC<ComponentProps & HeadingProps> = ({
  children,
  ...props
}) => (
  <Heading fontSize='24px' {...props} fontWeight='500'>
    {children}
  </Heading>
);

export const Body: React.FC<ComponentProps & TextProps> = ({
  children,
  ...props
}) => (
  <Text fontSize='md' {...props}>
    {children}
  </Text>
);

export const BodySmall: React.FC<ComponentProps & TextProps> = ({
  children,
  ...props
}) => (
  <Text fontSize='13px' {...props}>
    {children}
  </Text>
);

export const BodyBig: React.FC<ComponentProps & TextProps> = ({
  children,
  ...props
}) => (
  <Text fontSize='lg' {...props}>
    {children}
  </Text>
);
