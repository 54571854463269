import { ComponentStyleConfig } from '@chakra-ui/react';

export const buttonStyles: ComponentStyleConfig = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: '3xl', // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 3, // <-- px is short for paddingLeft and paddingRight
      py: 1, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      bg: 'brand.400',
      color: 'white',
      _hover: {
        bg: 'brand.500',
        _disabled: {
          bg: 'brand.500',
        },
      },
      _focus: {
        bg: 'brand.500',
      },
      _disabled: {
        bg: 'brand.500',
        border: '1px solid',
        borderColor: 'grey.200',
      },
    },
    secondary: {
      bg: 'grey.100',
      color: 'grey.900',
      _hover: {
        bg: 'grey.300',
      },
      _disabled: {
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'grey.200',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};
