import { ComponentStyleConfig } from '@chakra-ui/react';

export const tagStyles: ComponentStyleConfig = {
  baseStyle: {
    container: {
      whiteSpace: 'nowrap',
    },
  },
  sizes: {},
};
