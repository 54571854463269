import { ChakraProvider, Spinner, VStack } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { locationSettingsContext } from '../context/LocationSettingsContext';
import defaultTheme from '../theme';
import amplitude from 'amplitude-js';
import { AmplitudeProvider } from 'react-amplitude-hooks';

type WithCustomThemeProps = {
  children: React.ReactNode;
};

export const WithCustomTheme: React.FC<WithCustomThemeProps> = ({
  children,
}) => {
  const locationSettings = useContext(locationSettingsContext);

  const theme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      ...locationSettings.theme?.colors,
    },
  };

  const [amp, setAmp] = useState<any>();
  useEffect(() => {
    setAmp(amplitude.getInstance());
  }, []);

  return (
    <>
      {locationSettings.loading ? (
        <ChakraProvider theme={theme}>
          <VStack justifyContent='center' h='100vh'>
            <Spinner />
          </VStack>
        </ChakraProvider>
      ) : (
        <AmplitudeProvider
          apiKey={process.env.NEXT_PUBLIC_AMPLITUDE_KEY || ''}
          amplitudeInstance={amp}
        >
          <ChakraProvider theme={theme}>{children}</ChakraProvider>
        </AmplitudeProvider>
      )}
    </>
  );
};
