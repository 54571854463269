import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import client from '../apollo-client';

import { appWithTranslation } from 'next-i18next';
import {
  LocationSettingsContextProvider,
  locationSettingsContext,
} from '../toolkit/context/LocationSettingsContext';
import Head from 'next/head';
import '@fontsource/heebo';
import '@fontsource/heebo/500.css';
import '@fontsource/playfair-display';
import '@fontsource/playfair-display/500.css';
import '@fontsource/playfair-display/700.css';
import { WithCustomTheme } from '../toolkit/components/WithCustomTheme';
import { CookieConsent } from '../toolkit/components/CookieConsent';
import {
  locationPermissionsContext,
  LocationPermissionsContextProvider,
} from '../toolkit/context/LocationPermissionsContext';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <ApolloProvider client={client}>
        <LocationPermissionsContextProvider
          context={locationPermissionsContext}
        >
          <LocationSettingsContextProvider context={locationSettingsContext}>
            <WithCustomTheme>
              <Component {...pageProps} />
              <CookieConsent />
            </WithCustomTheme>
          </LocationSettingsContextProvider>
        </LocationPermissionsContextProvider>
      </ApolloProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
