import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { buttonStyles } from './button';
import { tabStyles } from './tab';
import { radioStyles } from './radio';
import { checkboxStyles } from './checkbox';
import { tagStyles } from './tag';

const styles = {
  global: {
    body: {
      background: 'white',
      // minHeight: '100vh',
    },
    // Fix missing modal footer on iOS 15.
    '.chakra-modal__content': {
      '@supports(height: -webkit-fill-available)': {
        minHeight: '-webkit-fill-available !important',
      },
    },
  },
};

const fonts = {
  heading: `'Playfair Display', serif`,
  body: `'Heebo', sans-serif`,
};

const theme = extendTheme({
  colors,
  styles,
  fonts,
  components: {
    Button: buttonStyles,
    Tabs: tabStyles,
    Radio: radioStyles,
    Checkbox: checkboxStyles,
    Tag: tagStyles,
  },
});

export default theme;
