import { HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { SecondaryButton } from './Buttons';
import { BodyBig } from './Texts';
import { parseCookies, setCookie } from 'nookies';
import { Cookie } from './icons/Cookie';
import { useEffect, useState } from 'react';

type CookieConsentProps = {};

export const CookieConsent: React.FC<CookieConsentProps> = () => {
  const { t } = useTranslation(['common']);

  const cookies = parseCookies();

  const [showCookies, setShowCookies] = useState(false);

  useEffect(() => {
    if (cookies.cookieConsent !== 'true') {
      setShowCookies(true);
    } else {
      setShowCookies(false);
    }
  }, [cookies]);

  const handleAcceptCookies = () => {
    setCookie(null, 'cookieConsent', 'true', {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });

    setShowCookies(false);
  };

  return (
    <>
      {showCookies && (
        <VStack
          bg='brand.400'
          p={4}
          borderRadius={12}
          position='fixed'
          bottom={5}
          right={5}
          spacing={0}
          gap={2}
          w='250px'
        >
          <HStack alignItems='center' spacing={0} gap={2}>
            <BodyBig color='white'>{t('cookieConsent.title')}</BodyBig>
            <Cookie color='white' size={60} />
          </HStack>
          <SecondaryButton onClick={handleAcceptCookies} data-testid='cookie-consent-button'>
            {t('cookieConsent.button')}
          </SecondaryButton>
        </VStack>
      )}
    </>
  );
};
