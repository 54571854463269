import {
  Button,
  ButtonProps,
  forwardRef,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';

type ComponentProps = {
  children: React.ReactNode;
};

export const PrimaryButton = forwardRef<ComponentProps & ButtonProps, 'div'>(
  (props, ref) => (
    <Button
      variant='primary'
      {...props}
      w='100%'
      fontWeight='500'
      py={0}
      px={8}
    />
  )
);

export const SecondaryButton = forwardRef<ComponentProps & ButtonProps, 'div'>(
  (props, ref) => (
    <Button
      variant='secondary'
      {...props}
      w='100%'
      fontWeight='500'
      py={0}
      px={8}
    />
  )
);

export const SecondaryButtonSmall = forwardRef<
  ComponentProps & ButtonProps,
  'div'
>((props, ref) => (
  <Button
    ref={ref}
    variant='secondary'
    size='sm'
    fontWeight='500'
    {...props}
    backgroundColor={props.isActive ? 'secondary.400' : 'grey.100'}
    _hover={{
      backgroundColor: props.isActive ? 'secondary.400' : 'grey.100',
    }}
    color={props.isActive ? 'white' : 'grey.900'}
  />
));

export const PrimaryIconButton: React.FC<IconButtonProps> = ({ ...props }) => (
  <IconButton variant='primary' size='sm' {...props} />
);

export const SecondaryIconButton: React.FC<IconButtonProps> = ({
  ...props
}) => <IconButton variant='secondary' size='sm' {...props} />;
